// eslint-disable-next-line no-unused-vars
const moduleName = "awsGuest";

const S3 = require("aws-sdk/clients/s3");
const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const {
  fromCognitoIdentityPool,
} = require("@aws-sdk/credential-provider-cognito-identity");
const AWSRegion = "us-east-2";

let storeModule;

function initialState() {
  return {
    IdentityPoolId: {
      production: "us-east-2:b72efbc0-3234-4005-a4b7-7142c0a178b5",
      test: "us-east-2:eac864f6-9dad-46b2-a9b9-0cba43d9651a",
    },
    BucketKey: {
      production: "pfyt-production",
      test: "pfyt-test",
    },
  };
}

storeModule = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      let oldProps = Object.keys(state);
      let newState = initialState();
      for (let prop of oldProps) {
        state[prop] = newState[prop];
      }
    },
  },
  actions: {
    async resetStore(context) {
      await context.dispatch("clearLocalStorage", null, { root: true });
      context.commit("resetState");
    },
    async getCredentials(context) {
      let creds = fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: AWSRegion }),
        identityPoolId: context.getters["IdentityPoolId"],
      });
      let credsReally = await creds();
      return credsReally;
    },
    async getS3Connection(context) {
      let credentials = await context.dispatch("getCredentials");
      let tempS3 = new S3({
        region: AWSRegion,
        credentials,
        params: {
          Bucket: context.getters["BucketKey"],
        },
      });
      return tempS3;
    },
    async saveS3Files(context, payload) {
      let S3 = await context.dispatch("getS3Connection");
      let filePromises = [];
      for (let file of payload.files) {
        filePromises.push(
          S3.putObject({
            ContentType: "application/json; charset=utf-8",
            Key: "pre-register/" + payload.id + "-" + file.name + ".json",
            Body: JSON.stringify(file.data),
          }).promise()
        );
      }
      return Promise.all(filePromises);
    },
  },
  getters: {
    IdentityPoolId(state, getters, rootState, rootGetters) {
      return state.IdentityPoolId[rootGetters["environment"]];
    },
    BucketKey(state, getters, rootState, rootGetters) {
      return state.BucketKey[rootGetters["environment"]];
    },
  },
};

export default storeModule;
